<template>
  <div class="wrapper">
    <div class="btn-group">
      <div>
        <admin-title :title="$route.meta.name"></admin-title>
      </div>
      <div>
        <el-button type="info" icon="el-icon-back" size="small" @click="$router.back()">返回</el-button>
      </div>
    </div>
    <a-divider />
  </div>
</template>

<script>
export default {
  name: 'Detail',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .btn-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
